@import '../scss/variables';

.root {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.015em;
  margin-top: 24px;
  position: relative;
  z-index: 1;
  max-width: 328px;
  @media (min-width: $view-lg) {
    max-width: 100%;
  }
}
