@import '../styles/variables';

.root {
  display: flex;
  position: relative;
  min-height: 56px;
  max-width: 100%;
  height: auto;
  justify-content: space-between;
  z-index: 3;
  cursor: pointer;
  align-items: center;
  padding-left: 16px;
}

.dark {
  color: var(--white);
  background: var(--deep-purple);
}

.light {
  color: var(--deep-purple);
  background: var(--white);
}

.title {
  letter-spacing: -0.025em;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding: 16px 0px;
  width: 100%;

  @media (min-width: $view-lg) {
    padding: 20px 0px;
  }
}

.icon {
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  border: 1px solid #d0c9d4;
  border-radius: 50%;
  margin-right: 16px;
  margin-left: 16px;
  transition: all 0.3s;
  position: relative;
  display: block;
  min-width: 32px;
  min-height: 32px;

  &:after {
    content: 's';
    font-family: 'athena-icons';
    font-size: 22px;
    line-height: 14px;
    font-weight: normal;
    position: absolute;
    left: calc(50% + 0.5px);
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.active {
  transform: rotate(45deg);
  transition: transform 0.6s ease;
  color: var(--bright-purple);
  border: 1px solid var(--bright-purple);
  &.dark {
    border: 1px solid var(--purple-light);
    color: var(--purple-light);
  }
}

.root:hover .icon {
  cursor: pointer;
  color: var(--bright-purple);
  border: 1px solid var(--bright-purple);
  &.dark {
    border: 1px solid var(--purple-light);
    color: var(--purple-light);
  }
}
