@import '../scss/variables';

.root {
  align-content: start;
  padding: 0 0 8px;

  @media (min-width: $view-sm) {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
  }

  @media (min-width: $view-md) {
    flex: 0 0 70%;
  }

  @media (min-width: $view-lg) {
    padding-top: 0;
  }
}
