@import '../styles/variables';

.root {
  margin-bottom: 24px;
}

.fields {
  width: 100%;
  height: 40px;
  border: 0;
  outline: var(--grey-bright);
  line-height: 48px;
  padding: 0 0 0 32px;
  font-size: 14px;
}

.error-msg {
  letter-spacing: -0.015em;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: red;
}

.helper-text-light {
  letter-spacing: -0.015em;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--grey-deep);
}

.helper-text-dark {
  letter-spacing: -0.015em;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.8;
  color: var(--grey-bright);
}

.fields-icons-light {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: solid var(--deep-purple);
  border-width: 1px 1px;
  color: var(--deep-purple);
  :focus {
    border: solid var(--bright-purple);
    border-width: 1px 1px;
  }
  ::placeholder {
    color: var(--deep-purple);
  }
}

.fields-icons-dark {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: solid var(--grey-bright);
  border-width: 1px 1px;
  color: var(--deep-purple);
  :focus {
    border: solid var(--grey-bright);
    border-width: 1px 1px;
  }
  ::placeholder {
    color: var(--grey-deep);
  }
}

.icon-input-fields {
  font-family: 'athena-icons';
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
  position: absolute;
  margin: 0;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.icon-input-fields-dark {
  opacity: 0.4;
}

.icon-input-fields-light {
  opacity: 1;
}

.label-optional-space {
  margin-bottom: 4px;
}

.label-fonts {
  letter-spacing: -0.025em;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.label-dark {
  color: var(--grey-bright);
}

.label-light {
  color: var(--deep-purple);
}

.optional-fonts-light {
  letter-spacing: -0.015em;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--deep-purple);
  opacity: 1;
  float: right;
}

.optional-fonts-dark {
  letter-spacing: -0.015em;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--grey-bright);
  float: right;
}

.text-area {
  width: 100%;
  border: 0;
  outline: var(--grey-bright);
  line-height: 48px;
  padding: 0 0 0 32px;
  height: 200px;
}

.text-area-icons-wrapper-light {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: solid var(--deep-purple);
  border-width: 1px 1px;
  color: var(--deep-purple);
  opacity: 1;
  :focus {
    border: solid var(--bright-purple);
    border-width: 1px 1px;
  }
  ::placeholder {
    color: var(--deep-purple);
  }
}

.text-area-icons-wrapper-dark {
  width: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: solid var(--grey-bright);
  border-width: 1px 1px;
  color: var(--deep-purple);
  :focus {
    border: solid var(--grey-bright);
    border-width: 1px 1px;
  }
  ::placeholder {
    color: var(--grey);
    opacity: 0.4;
  }
}

.dark {
  opacity: 0.4;
}
.light {
  opacity: 1;
}

.icon-text-area {
  font-family: 'athena-icons';
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
  position: absolute;
  margin: 0;
  top: 14px;
  left: 4px;
}
