@import '../styles/variables';

.root {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.48, 0.23, 1, 1);
  width: 100%;
  text-align: center;

  &.button {
    padding: 0 2px;
  }
}

.inline {
  margin-right: 20px;
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    background-color: var(--purple-light);
    width: 24px;
    height: 4px;
    opacity: 0;
  }
  &:hover {
    &::after {
      opacity: 1;
      bottom: -16px;
      background-color: var(--bright-accent);
    }
  }
}

.active {
  &::after {
    opacity: 1;
    bottom: -16px;
  }
}

.dark {
  color: var(--white);
  &::after {
    color: var(--purple-light);
    background-color: var(--purple-light);
  }
  &:hover {
    &::after {
      background-color: var(--purple-light);
    }
  }
}

.light {
  color: var(--deep-purple);
}

.button {
  align-self: center;
  height: 32px;
  padding: 0 16px;
  line-height: 32px;
  background-color: var(--white);
  border-radius: 4px;
  margin: 0 2px;
  &.dark {
    background-color: var(--deep-accent);
    color: var(--white);
  }
  &.active {
    background-color: var(--deep-accent);
    color: var(--white);
    &.dark {
      background-color: var(--deep-accent);
      color: var(--deep-accent);
    }
  }

  .dark {
    color: var(--purple-light);
    background-color: var(--deep-purple);
  }
}
