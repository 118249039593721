@import '../scss/variables';

.root {
  margin: 0 -16px;
  position: relative;
  z-index: 1;
  @media (min-width: $view-lg) {
    display: flex;
    justify-content: space-between;
  }
}
