@import '../styles/variables';

.root {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(121, 102, 133, 0.5);
  border-radius: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 8px;
  max-width: 1280px;

  @media (max-width: $view-sm) {
    flex-direction: column;
    min-width: 344px;
  }
}
