@import '../styles/variables';

.root {
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  &:hover {
    .pink {
      background-color: var(--funnel-accent);
    }
  }
}

.pink {
  position: absolute;
  background-color: var(--funnel);
  height: 130px;
  top: -20px;
  width: calc(100% + 32px);
  left: calc(100% - 50px);
  transform: rotateZ(15deg);
}

.icon {
  font-family: 'athena-icons';
  font-size: 22px;
  line-height: 14px;
  font-weight: normal;
  position: absolute;
  right: 10px;
}

.large {
  height: 32px;
  @media (min-width: $view-lg) {
    height: 40px;
  }
  padding: 0 52px 0 5px;
  font-size: 14px;
  letter-spacing: -0.025em;
}

.primary {
  border: none;
  background-color: var(--grey-bright);
  color: var(--white);
}
