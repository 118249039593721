@import '../styles/variables';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.block {
  display: flex;
  flex-direction: row;
  margin: 0 0 16px 0;
  padding: 16px;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
  @media (min-width: $view-lg) {
    padding: 24px;
  }
}
.image {
  position: relative;
  margin-right: 16px;
  align-self: center;
}

.text {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.link {
  display: flex;
  align-items: center;
  margin-left: 16px;
}
