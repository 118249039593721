@import '../styles/variables';
.root {
  margin-bottom: 12px;
}
.dark {
  max-width: 100%;
}

.light {
  max-width: 100%;
}
