@import '../styles/variables';

.root {
  display: flex;
  z-index: 1;
  min-width: 320px;
  width: auto;
  background: var(--white);
}

.dark {
  color: var(--white);
  background: var(--deep-purple);
  a {
    color: var(--purple-light);
    text-decoration: underline;
  }
}

.light {
  color: var(--grey-deep);
  background: var(--white);
  a {
    color: var(--bright-purple);
    text-decoration: underline;
  }
}

.body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  min-width: 20px;
  width: auto;
  letter-spacing: -0.015em;
  flex-direction: column;
  padding: 12px 16px;
  min-height: 20px;
  margin-bottom: 0;
  margin-top: -12px;
}
