@import '../styles/variables';

.root {
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: var(--white);
  height: 100%;
  align-content: center;
  display: flex;
  padding: 16px;
  @media (min-width: $view-lg) {
    margin-right: 24px;
    padding: 24px;
  }
}

.elevated {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  margin-bottom: 0px;

  @media (min-width: $view-lg) {
    margin-bottom: 24px;
  }
}

.image {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    margin-bottom: 16px;
  }
}

.desktopImage {
  display: none;
  @media (min-width: $view-lg) {
    display: block;
  }
}

.mobileImage {
  display: block;
  @media (min-width: $view-lg) {
    display: none;
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.text {
  padding-right: 8px;
  align-self: center;
  text-align: center;
}
