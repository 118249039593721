@import '../scss/colours';
@import '../scss/variables';

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.025em;
  color: var(--purple-light);
  margin: 1rem 0;
}

.root {
  padding: 16px;

  @media (min-width: $view-sm) {
    flex: 0 0 calc(1 / 2 * 100%);
  }
  @media (min-width: $view-md) {
    flex: 0 0 calc(1 / 3 * 100%);
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  padding: 6px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.015em;
}
