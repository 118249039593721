@import '../scss/colours';
@import '../scss/variables';

.root {
  background: var(--mid-purple);
  bottom: 804px;
  display: block;
  height: 1600px;
  left: -32px;
  position: absolute;
  transform: rotate(97deg) scaleX(2);
  transform-origin: bottom left;
  width: 1600px;
  z-index: 0;

  @media (min-width: $view-md) {
    bottom: 782px;
    transform: rotate(93deg) scaleX(2);
  }

  @media (min-width: $view-lg) {
    bottom: 0;
    left: 66%;
    transform: rotate(4deg);
  }
}
