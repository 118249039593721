@import '../scss/colours';
@import '../scss/variables';

.root {
  background-color: $grey-100;
}

.container {
  margin: 0 auto;
  max-width: $layout-width;
  padding: 24px;
}

.row {
  display: flex;
  flex-direction: column;
  margin: 0 -8px;

  @media (min-width: $view-sm) {
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

.column {
  flex: 0;
  max-width: 100%;
  padding: 0 8px;
}

.flags {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.flag {
  margin-right: 8px;
}

.copy {
  text-align: center;
  @media (min-width: $view-sm) {
    flex: 0 1 auto;
    text-align: left;
  }
}
