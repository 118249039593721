@import '../styles/variables';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  color: var(--deep-purple);
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: 600;
  @media (max-width: $view-sm) {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    width: 98%;
  }
  &:hover {
    color: var(--white);
    background-color: var(--bright-purple);
  }
}

.dark {
  color: var(--deep-purple);
}

.light {
  color: var(--deep-purple);
}

.image {
  @media (min-width: $view-lg) {
    margin-bottom: 8px;
    margin-left: auto;
    margin-right: auto;
  }
}

.text {
  @media (max-width: $view-sm) {
    margin-left: 12px;
    margin-top: 0px;
  }
  justify-content: flex-start;
  margin-top: 8px;
}

.active {
  color: var(--white);
  background-color: var(--deep-purple);
}
