@import '../scss/colours';
@import '../scss/helpers';
@import '../scss/variables';

.root {
  background: var(--deep-purple);
  color: var(--white);
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.root a {
  box-shadow: inset 0 -2px 0 0 transparent;
  color: var(--white);
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.025em;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;

  &:focus,
  &:hover {
    color: var(--funnel-accent);
  }
}

.container {
  margin: auto;
  max-width: $layout-width;
  padding: 16px 24px;
  position: relative;

  @media (min-width: $view-sm) {
    padding: 32px 32px 16px;
  }

  @media (min-width: $view-lg) {
    padding: 24px 32px 24px;
  }
}
