@import '../scss/variables';

.root {
  display: flex;
  max-width: 300px;
  svg {
    margin-right: 8px;
    width: 24px;
    height: 24px;
    min-width: 20px;
  }
  @media (min-width: $view-lg) {
    max-width: 100%;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  margin: 16px 0;
  padding-left: 40px;
  position: relative;
  text-transform: uppercase;
}

.image {
  display: block;
  left: 0;
  position: absolute;
  top: -12px;
}

.paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.015em;
}
