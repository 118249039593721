@import '../scss/variables';

.root {
  align-items: flex-start;
  flex: 0 0 256px;
  padding-bottom: 16px;

  @media (min-width: $view-lg) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}
