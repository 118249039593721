// ** Salt **
$salt: a27y4h;

// ** Viewport breakpoints **
$view-xs: 30rem; // 480px
$view-sm: 35rem; // 560px
$view-md: 48rem; // 768px
$view-lg: 72rem; // 1152px
$view-xl: 80rem; // 1280px
$view-xx: 90rem; // 1,440px

// ** Widths **
$layout-width: 80rem; // 1280px
