.root {
  margin-bottom: 12px;
}

.expander {
  overflow-y: hidden;
  will-change: max-height, transform;
  transform-style: preserve-3d;
  transform-origin: top;
}

.text {
  will-change: opacity;
}
