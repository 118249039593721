@import '../styles/variables';

.root {
  position: relative;
}

// mobile

.mobile-huge-top {
  padding-top: 96px;
}

.mobile-extra-large-top {
  padding-top: 64px;
}

.mobile-large-top {
  padding-top: 32px;
}

.mobile-medium-top {
  padding-top: 32px;
}

.mobile-small-top {
  padding-top: 16px;
}

.mobile-zero-top {
  padding-bottom: 0;
}

.mobile-huge-bottom {
  padding-bottom: 64px;
}

.mobile-extra-large-bottom {
  padding-bottom: 64px;
}

.mobile-large-bottom {
  padding-bottom: 32px;
}

.mobile-medium-bottom {
  padding-bottom: 32px;
}

.mobile-small-bottom {
  padding-bottom: 16px;
}

.mobile-zero-bottom {
  padding-bottom: 0;
}

//desktop

.desktop-huge-top {
  @media (min-width: $view-lg) {
    padding-top: 200px;
  }
}

.desktop-extra-large-top {
  @media (min-width: $view-lg) {
    padding-top: 128px;
  }
}

.desktop-large-top {
  @media (min-width: $view-lg) {
    padding-top: 96px;
  }
}

.desktop-medium-top {
  @media (min-width: $view-lg) {
    padding-top: 64px;
  }
}

.desktop-small-top {
  @media (min-width: $view-lg) {
    padding-top: 32px;
  }
}

.desktop-zero-top {
  @media (min-width: $view-lg) {
    padding-top: 32px;
  }
}

.desktop-huge-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 200px;
  }
}

.desktop-extra-large-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 128px;
  }
}

.desktop-large-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 96px;
  }
}

.desktop-medium-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 64px;
  }
}

.desktop-small-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 32px;
  }
}

.desktop-zero-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 0;
  }
}

.clipped {
  clip-path: polygon(0 0, 82% 0, 98% 30%, 90% 80%, 90% 100%, 0 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @media (min-width: $view-lg) {
    clip-path: polygon(0 0, 20% 0, 50% 45%, 45% 80%, 50% 100%, 0 100%);
  }
}

.double-clipped {
  clip-path: polygon(0 0, 84% 0, 98% 35%, 92% 80%, 91% 100%, 0 100%);
  background: var(--bright-accent);
  @media (min-width: $view-lg) {
    clip-path: polygon(0 0, 22% 0, 50% 45%, 47% 80%, 51% 100%, 0 100%);
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.clipped-responsive {
  clip-path: polygon(0 0, 100% 0, 100% 40%, 50% 50%, 0 40%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  @media (min-width: $view-lg) {
    clip-path: polygon(0 0, 20% 0, 50% 45%, 45% 80%, 50% 100%, 0 100%);
  }
}

.double-clipped-responsive {
  clip-path: polygon(0 0, 100% 0, 100% 44%, 50% 50%, 0 44%);
  background: var(--bright-accent);
  @media (min-width: $view-lg) {
    clip-path: polygon(0 0, 22% 0, 50% 45%, 47% 80%, 51% 100%, 0 100%);
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.top-inclined {
  clip-path: polygon(0 15%, 0 100%, 100% 100%, 100% 0);
  position: absolute;
  top: -35px;
  height: 36px;
  width: 100%;
  @media (min-width: $view-lg) {
    clip-path: polygon(0 25%, 0 100%, 100% 100%, 100% 0);
    top: -170px;
    height: 170px;
  }
}

.bottom-inclined {
  width: 100%;
  position: absolute;
  z-index: 2;
  display: block;
  bottom: -35px;
  height: 36px;
  clip-path: polygon(0 0%, 0 100%, 100% 5%, 100% 0);
  @media (min-width: $view-lg) {
    clip-path: polygon(0 0%, 0 100%, 100% 5%, 100% 0);
    bottom: -127px;
    height: 128px;
  }
}

.reverse-bottom-inclined {
  width: 100%;
  position: absolute;
  z-index: 2;
  display: block;
  bottom: -35px;
  height: 36px;
  clip-path: polygon(-5% 0%, 100% 0%, 100% 100%);
  @media (min-width: $view-lg) {
    clip-path: polygon(-5% 0%, 100% 0%, 100% 100%);
    bottom: -127px;
    height: 128px;
  }
}

.v-bottom {
  width: 100%;
  position: absolute;
  z-index: 2;
  display: block;
  clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 60% 100%, 0% 0%);
  bottom: -49px;
  height: 50px;
  @media (min-width: $view-lg) {
    bottom: -127px;
    height: 128px;

    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 50%,
      60% 100%,
      0% 0%
    ); //https://developer.mozilla.org/en-US/docs/Web/CSS/basic-shape/polygon
  }
}

.white {
  background: var(--white);
}
