@import '../styles/variables';

.root {
  display: flex;
  flex-direction: row;
}

.no-stretch {
}

.button {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  width: 100%;
  padding: 4px 2px;
}

.active {
  &::after {
    opacity: 1;
    bottom: -16px;
  }
}

.dark {
  color: var(--white);
  &::after {
    color: var(--purple-light);
    background-color: var(--white);
  }
  &:hover {
    &::after {
      background-color: var(--purple-light);
    }
  }
}

.light {
  color: var(--deep-purple);
}
