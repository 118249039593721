@import '../styles/variables';

.root {
  width: 100%;
  height: 100%;
  display: grid;
  overflow: hidden;
  @media (min-width: $view-lg) {
    flex-direction: row;
  }
}

.full-elevation {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
}

.imageWrapper {
  margin-bottom: 24px;
  overflow: hidden;
  @media (min-width: $view-lg) {
    padding-right: 48px;
    margin-bottom: 0;
  }
}

.tabBarWrapper {
  display: flex;
  justify-content: center;
  @media (min-width: $view-lg) {
    justify-content: flex-start;
  }
}

.listItem {
  padding: 18px 0;
  display: flex;
  border-bottom: 1px solid var(--grey-mid);
  span {
    font-size: 16px;
    margin-right: 16px;
  }
  span::fist-child {
    margin-right: 8px;
  }
  &.dark {
    span {
      color: var(--white);
      &.info {
        color: var(--purple-light);
      }
    }
  }
  &.light {
    span {
      color: var(--deep-purple);
      &.info {
        color: var(--mid-purple);
      }
    }
  }
}

.contactInfo {
  padding: 16px;
  max-width: calc(100vw - 32px);
  @media (min-width: $view-lg) {
    padding: 24px;
  }
}

.list {
  max-width: 320px;
  padding: 0;
  .icon {
    font-size: 12px;
    padding-left: 8px;
    margin-right: 8px;
  }
  .listItem {
    padding: 6px 0 6px 16px;
    line-height: 24px;
    border-bottom: 0.5px solid #210234;
  }
}
