@import '../scss/variables';

.root {
  @media (min-width: $view-sm) {
    flex: 0 0 100%;
  }

  @media (min-width: $view-md) {
    flex: 0 0 calc(3 / 4 * 100%);
  }
}

.mobile {
  display: block;
  padding: 0px 16px;
  @media (min-width: $view-lg) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (min-width: $view-lg) {
    padding: 16px;
    display: block;
  }
}
