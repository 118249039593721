@import '../styles/variables';

.root {
  padding: 16px;
}

.dark {
  color: var(--white);
  background: var(--deep-purple);
}

.comparison {
  max-width: 330px;
  margin-top: 10px;
  @media (min-width: $view-lg) {
    max-width: 400px;
    margin-top: 0px;
  }
}

.legalHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 24px;
  @media (min-width: $view-lg) {
    flex-direction: row;
  }
}

.line {
  padding-left: 16px;
  padding-right: 24px;
  padding-bottom: 16px;
  opacity: 0.5;
}
