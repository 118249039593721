@import '../scss/variables';

.root {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 12px 0;
  max-width: 248px;
  padding: 4px;
  a {
    cursor: pointer;
    display: block;
    margin: 12px;
    align-self: end;
    &:hover {
      box-shadow: inset 0 -2px 0 0 transparent;
    }
  }

  @media (min-width: $view-sm) {
  }

  @media (min-width: $view-lg) {
    max-width: 248px;
  }
}

.image {
  display: block;
}
