@import '../styles/variables';

.root {
  position: absolute;
  top: calc(25% + 36px);
  z-index: 9444;
  background-color: black;
  width: 100%;
  height: 100%;
}

.active {
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.3s ease-in-out;
  margin-top: 48px;
  @media (max-width: $view-lg) {
    position: absolute;
    top: 12%;
  }  
  @media (min-width: $view-lg) {
    margin-top: 0px;
  }
}

.inactive {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  margin-top: 48px;
  @media (max-width: $view-lg) {
    position: absolute;
    top: 12%;
  }   
  @media (min-width: $view-lg) {
    margin-top: 0px;
  }
}

.intro {
  padding-top: 24px;
  padding-left: 16px;
  max-width: 80%;
  @media (min-width: $view-lg) {
    max-width: 504px;
    padding-left: 48px;
    max-width: 55%;
  }
  transition: all 0.6s ease-in-out;
}

.btn {
  padding-bottom: 28px;
  @media (min-width: $view-lg) {
    position: relative;
    padding-top: 40px;
  }
}

.arr {
  color: white;
  background: transparent;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 50%;
  position: absolute;
  margin-top: 24px;
  margin-right: 16px;
  right: 0;
  cursor: pointer;
  transition: transform 0.6s ease-in-out;
  z-index: 9999;
  @media (min-width: $view-lg) {
    margin-right: 48px;
  }
}

.img {
  @media (min-width: $view-lg) {
    position: absolute;
    left: 60%;
    top: 0.25%;
    padding-left: 0px;
    padding-right: 0px;
  }
  position: relative;
  padding-left: 15.5px;
  padding-right: 15.5px;
  padding-top: 24px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;

  .relatedOverlay {
    height: 294px;
    position: absolute;
    width: 345px;
    opacity: 0;
    transition: 0.3s ease opacity;
    &::before {
      content: '';
      background: rgba(0, 0, 0, 0.6);
      position: absolute;
      left: 0;
      top: 52px;
      height: 220px;
      width: 345px;
      @media (min-width: $view-lg) {
        top: 64px;
        left: 0;
      }
    }
  }
  &:hover {
    .relatedOverlay {
      opacity: 1;
      p {
        position: absolute;
        top: 150px;
        left: 170px;
        transform: translate(-50%, -50%);
        color: white;
        @media (min-width: $view-lg) {
          top: 162px;
          left: 172px;
        }
      }
      &::before {
        display: block;
      }
    }
  }
}

.arrow {
  position: absolute;
  left: calc(50% + 0.5px);
  top: calc(50% + 3px);
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  line-height: 14px;
  font-weight: normal;
  z-index: 9999;
}

.arrow-active {
  transform: rotate(180deg);
  transition: transform 0.6s ease-in-out;
}
