@import '../styles/variables';

.mobile-left {
  display: flex;
  justify-content: flex-start;
}

.mobile-center {
  display: flex;
  justify-content: center;
}

.mobile-right {
  display: flex;
  justify-content: flex-end;
}

@media (min-width: $view-lg) {
  .desktop-left {
    display: flex;
    justify-content: flex-start;
  }

  .desktop-center {
    display: flex;
    justify-content: center;
  }

  .desktop-right {
    display: flex;
    justify-content: flex-end;
  }
}

.engager {
  display: flex;
  justify-content: center;
  @media (min-width: $view-lg) {
    display: block;
    justify-content: initial;
  }
  &.mobile-left {
    display: flex;
    justify-content: flex-start;
  }

  &.mobile-center {
    display: flex;
    justify-content: center;
  }

  &.mobile-right {
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: $view-lg) {
    &.desktop-left {
      display: flex;
      justify-content: flex-start;
    }

    &.desktop-center {
      display: flex;
      justify-content: center;
    }

    &.desktop-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}
