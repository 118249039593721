@import '../styles/variables';

.text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    height: 100%;
    margin: 0 auto;
  
    @media (min-width: $view-lg) {
      margin: 0;
      padding: 0 48px;
    }
}

.extra-margin {
    padding-top: 24px;
    padding-bottom: 24px;
    color: var(--white);
    @media (max-width: $view-lg) {
      text-align: center;
    }
}