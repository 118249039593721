.root {
  flex-direction: column;
  max-width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
}

.dark {
  color: var(--white);
}

.light {
  color: var(--deep-purple);
}
