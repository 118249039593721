.image {
  display: block;
  margin: 0 auto;
  max-width: unset;
  transform: translateZ(0);
  img{
    max-width:calc(100vw - 72px);
  }
}

.image.full-width {
  max-width: 1920px;
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}

.mobile {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

