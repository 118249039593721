@import '../../styles/variables';

.root {
  padding-top: 32px;
  position: relative;
  margin-top: -4rem;

  @media (min-width: $view-md) {
    padding-top: 64px;
  }
}

.container {
  z-index: 1;
  overflow-x: visible;
  position: relative;
  max-width: 850px;

  /* Spacing */
  margin: 0 auto;
  margin-top: 32px;
}

.trustPilot {
  margin: auto !important;
  height: 124px !important;
  width: 141px !important;

  @media (min-width: $view-md) {
    margin: unset !important;
  }
}

.layout {
  z-index: 4;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: $view-md) {
    flex-direction: column;
  }
}

.verticalDivider {
  border: none;
  display: none;

  @media (min-width: $view-md) {
    margin: 0px;

    width: 1px;
    height: 40px;
    display: block;
    background: rgba(255, 255, 255, 0.24);
  }
}

.horizontalDivider {
  width: 100%;
  height: 1px;
  border: none;
  margin: none;
  background: rgba(255, 255, 255, 0.24);

  @media (min-width: $view-md) {
    display: none;
  }
}

.gridContainer {
  /* Spacing */
  padding-left: 0px;
  margin-bottom: 8px;

  @media (min-width: $view-md) {
    margin-bottom: 48px;
  }

  /* Flex styling */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 24px;

  .gridItem {
    /* List styling */
    margin: 0;
    padding: 0;
    list-style-type: none;

    width: 50%;
    height: 50%;
    color: white;
    flex-wrap: wrap;
    position: relative;

    /* Display & Alignment */
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    row-gap: 4px;

    @media (min-width: $view-md) {
      max-height: 124px;
      max-width: 141px;
      row-gap: 8px;
    }

    .caption {
      text-align: center;
      font-size: 10px;
      line-height: 14px;
      font-weight: 600;
      margin-bottom: -4px;
    }

    .rateContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      .ratePercentage {
        margin-left: 2px;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;

        @media (min-width: $view-md) {
          font-size: 16px;
        }
      }
    }

    .headline {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;

      @media (min-width: $view-md) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .subline {
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
      padding: 0px 24px;

      @media (min-width: $view-md) {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}

.titleContainer {
  z-index: 5;
  @media (min-width: $view-md) {
    margin-top: 0px;
  }

  .title {
    letter-spacing: -1px;
    font-size: 50px;
    line-height: 48px;
    font-weight: 900;
    text-align: center;
    color: white;

    /* Mobile breakpoint */
    @media (min-width: $view-md) {
      font-size: 100px;
      line-height: 98px;
      margin-bottom: 48px;
      letter-spacing: -2px;
    }
  }

  .underline {
    position: relative;
    &:after {
      content: '';
      /* Positioning */
      left: 0;
      bottom: -24px;
      position: absolute;

      /* Measurements */
      width: 100%;
      height: 25px;

      /* Image scaling */
      object-fit: fill;
      background-repeat: no-repeat !important;
      background: url('/next-assets/images/scribble.svg');

      @media (min-width: $view-md) {
        width: 100%;
        height: 33px;
      }
    }
  }

  .description {
    font-size: 18px;
    line-height: 24px;
    padding: 24px 58px 0px 58px;

    color: white;
    text-align: center;
    margin-bottom: 40px;
    @media (min-width: $view-md) {
      padding: 0px 58px;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.ctaContainer {
  padding-bottom: 40px;
  opacity: 100% !important;

  gap: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: $view-md) {
    padding-bottom: 48px;
  }
}

.switchCohortContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -24px;
  padding-bottom: 40px;
  position: relative;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);

  @media (min-width: $view-md) {
    margin-top: -8px;
    padding-bottom: 48px;
  }
}

.videoPlayer {
  /* Mobile dimenions */
  width: 100%;
  height: 100%;
  max-height: 250px;

  /* Positioning */
  z-index: 4;
  left: 0px;

  /* Spacing */
  padding: 0px 16px;

  @media (min-width: $view-md) {
    width: 100%;
    height: 100%;
    max-height: 440px;
    padding: 0px;
  }
}

.cohortButtonContainer {
  gap: 6px;
  display: flex;
  margin-top: 64px;
  align-items: center;
  justify-content: center;

  @media (min-width: $view-md) {
    display: none;
  }
}

.cohortButton {
  /* Colours */
  color: white;
  background: rgba(255, 255, 255, 0.25);

  /* Border & Shadow */
  border: 0;
  border-radius: 32px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05), 0px 24px 60px 0px rgba(6, 47, 125, 0.05),
    0px 2px 4px 0px rgba(7, 4, 146, 0.1);

  /* Font & Cursor */
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.4px;

  /* Positioning & Spacing */
  margin: 0;
  z-index: 1;
  display: block;
  padding: 8px 16px;
  position: relative;

  /* Transitions */
  transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

  &:hover {
    background-color: rgba(235, 227, 255, 1);
  }

  &.active {
    background-color: #e5daff;
    color: var(--bright-purple);
  }
}

/* Mobile Transitions */
.videoPlaceholder {
  width: 100%;
  height: 230px;
  transition: 0.3s ease-in-out;

  @media (min-width: $view-xs) {
    width: 100%;
  }

  @media (min-width: $view-sm) {
    margin: auto;
    width: 451px;
    height: 255px;
  }

  @media (min-width: $view-md) {
    width: 818px;
    height: 430px;
  }
}
