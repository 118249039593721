@import '../styles/variables';

.root {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  @media (min-width: $view-lg) {
    a {
      align-self: center;
    }
  }
}

.full-elevation {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
}

.left {
  img {
    object-position: left;
  }
}
.center {
  img {
    object-position: center;
  }
}
.right {
  img {
    object-position: right;
  }
}

.play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.cta {
  margin: 32px 0;
}

.left {
  display: flex;
  justify-content: flex-start;
}

.center {
  display: flex;
  justify-content: center;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.mobileImage {
  display: block;
  width: 100%;
  position: relative;
  align-self: center;
  @media (min-width: $view-xs) {
    display: none;
  }
  &.cover {
    width: 100%;
    height: 100%;
    a {
      width: 100%;
    }
    img {
      object-fit: cover;
    }
  }
}

.tabletImage {
  display: none;
  width: 100%;
  position: relative;
  align-self: center;
  @media (min-width: $view-xs) {
    display: block;
  }
  @media (min-width: $view-lg) {
    display: none;
  }
  &.cover {
    width: 100%;
    height: 100%;
    a {
      width: 100%;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: translateZ(0);
    }
  }
}

.desktopImage {
  display: none;
  width: 100%;
  position: relative;
  align-self: center;

  @media (min-width: $view-lg) {
    display: block;
  }
  &.cover {
    width: 100%;
    height: 100%;
    a {
      width: 100%;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: translateZ(0);
    }
  }
}
