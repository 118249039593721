@import '../styles/variables';


.root {
    width: 100%;
    position: relative;
    height: 504px;
    cursor: pointer;
}

.story {
    position: absolute;
    bottom: 16px;
    padding-left: 16px;
    max-width: 320px;
    @media(min-width: $view-lg) {
      padding-left: 48px;
      max-width: 488px;
    }
}

.icon {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    // border: 1px solid #d0c9d4;
    border-radius: 50%;
    transition: all 0.3s;
    position: relative;
    display: block;
    margin-bottom: 24px;
    cursor: pointer;

    @media (min-width: $view-lg) {
      margin-bottom: 32px;
    }   
    &:after {
      content: 'd';
      font-family: 'athena-icons';
      font-size: 22px;
      line-height: 14px;
      font-weight: normal;
      position: absolute;
      left: calc(50% + 0.5px);
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .play {
    position: absolute;
    top: 10px;
    left: 64px;
    @media (min-width: $view-lg) {
      left: 100px;
    }
  }