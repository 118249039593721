@import '../styles/variables';

.root {
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  transition: height 0.3s ease-in-out;
  @media (min-width: $view-lg) {
    flex-direction: row;
    height: 488px;
  }
}

.full-elevation {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
}

.imageWrapper {
  margin-bottom: 24px;
  overflow: hidden;
  @media (min-width: $view-lg) {
    padding-right: 48px;
    margin-bottom: 0;
  }
}

.tabBarWrapper {
  margin: 32px 0;
  display: flex;
  justify-content: center;
  @media (min-width: $view-lg) {
    justify-content: flex-start;
  }
}

.tabBar {
  display: block;
  width: 100%;
  padding: 0 16px;

  @media (min-width: $view-lg) {
    display: flex;
    justify-content: center;
    justify-content: flex-start;
    width: calc(50% - 48px);
    position: absolute;
    top: 48px;
    padding: 0 32px;
  }
}

.tabContent {
  margin-top: 32px;
  padding: 0 16px;

  @media (min-width: $view-lg) {
    margin-top: 88px;
    padding: 0 32px;
  }
}
