@import '../scss/variables';

.root {
  align-items: center;
  display: flex;
  margin-bottom: 32px;
  margin-top: 16px;

  a:hover,
  a:focus {
    box-shadow: none;
  }

  @media (min-width: $view-md) {
    margin-top: 0;
  }
}
