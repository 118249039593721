@import '../styles/variables';

.root {
  max-width: 408px;
  height: 136px;
  background: var(--white);
  /* Shadow Style */
  padding: 24px 10px;
  @media (min-width: $view-lg) {
    padding: 24px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
  }
}

.tabBar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  @media (min-width: $view-lg) {
    align-items: initial;
    justify-content: initial;
    margin-left: initial;
  }
  &.mobile-left {
    justify-content: flex-start;
  }
  &.mobile-center {
    justify-content: center;
  }
  &.mobile-right {
    justify-content: flex-end;
  }

  @media (min-width: $view-lg) {
    &.desktop-left {
      justify-content: flex-start;
    }
    &.desktop-center {
      justify-content: center;
    }
    &.desktop-right {
      justify-content: flex-end;
    }
  }
}

.engagerBorderContainer {
  height: 48px;
  border-radius: 4px;
  background-color: var(--purple-deep);
  margin-top: 32px;

  display: flex;
  &.mobile-left {
    justify-content: flex-start;
  }
  &.mobile-center {
    justify-content: center;
  }
  &.mobile-right {
    justify-content: flex-end;
  }

  @media (min-width: $view-lg) {
    &.desktop-left {
      justify-content: flex-start;
    }
    &.desktop-center {
      justify-content: center;
    }
    &.desktop-right {
      justify-content: flex-end;
    }
  }
}

.inputContainer {
  width: fit-content;
  height: 40px;
  background-color: var(--grey-bright);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 4%), -5px 4px 16px rgb(0 0 0 / 8%);
}

.large {
  padding: 4px;
  border-radius: 4px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04)) drop-shadow(-5px 4px 16px rgba(0, 0, 0, 0.08));
  @media (min-width: $view-lg) {
    height: 56px;
    padding: 8px;
  }
}

.textContainer {
  height: 14px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.025em;
  color: var(--purple-deep);
  margin-left: 4px;
  @media (max-width: $view-md) {
    font-size: 14px;
    width: 180px;
  }
}
