@import '../styles/variables';

.root {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);
  width: 100%;
}

.icon {
  font-family: 'athena-icons';
  font-size: 20px;
  line-height: 14px;
  font-weight: normal;
  padding-right: 4px;
}

.light {
  &.darker {
    color: var(--deep-accent);
  }
  &.lighter {
    color: var(--bright-accent);
  }
  &.mid {
    color: var(--bright-purple);
  }
}

.dark {
  &.darker {
    color: var(--bright-accent);
  }
  &.lighter {
    color: var(--white);
  }
  &.mid {
    color: var(--bright-purple);
  }
}

.margin-bottom-large {
  margin-bottom: 16px;
  @media (min-width: $view-lg) {
    margin-bottom: 40px;
  }
}

.margin-bottom-medium {
  margin-bottom: 20px;
  @media (min-width: $view-lg) {
    margin-bottom: 32px;
  }
}

.margin-bottom-small {
  margin-bottom: 16px;
  @media (min-width: $view-lg) {
    margin-bottom: 24px;
  }
}

.margin-bottom-tiny {
  margin-bottom: 8px;
  @media (min-width: $view-lg) {
    margin-bottom: 16px;
  }
}

.mobile-left {
  text-align: left;
  justify-content: flex-start;
}

.mobile-center {
  text-align: center;
  justify-content: center;
}

.mobile-right {
  text-align: right;
  justify-content: flex-end;
}

@media (min-width: $view-lg) {
  .desktop-left {
    text-align: left;
    justify-content: flex-start;
  }

  .desktop-center {
    text-align: center;
    justify-content: center;
  }

  .desktop-right {
    text-align: right;
    justify-content: flex-end;
  }
}
