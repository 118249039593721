@import '../styles/variables';

.root {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  font-feature-settings: "liga" on, "clig" on, "dlig" off;
  text-rendering: optimizeSpeed;
  touch-action: pan-y;
  overflow: visible;

  [data-filler="fmid"] {
    background-color: var(--deep-accent);
    opacity: 1;
    transition: opacity 0.3s ease-in;
    z-index: 4;
    width: 283px;
    height: 283px;
    position: absolute;
    background-image: none;
   }
  
   [data-filler="fbottom"]{
    background-color: var(--deep-accent);
    opacity: 1;
    transition: opacity 0.3s ease-in;
    z-index: 4;
    width: 283px;
    height: 283px;
    position: absolute;
    background-image: none;
   }
  
  
  
  [data-radio="active"]{
    color: var(--deep-accent);
    opacity: 1;
   }
  
  
  [data-name="slide"] {
    position: absolute;
    width: 283px;
    height: 283px;
    transition: left 0.3s ease-out, opacity 0.3s ease-out;
    will-change: transform;
  }
  
  [data-pos="top"] {
    z-index: 3;
    transition: transform 0.3s ease-in;
  }
  
  [data-pos="middle"] {
    transform: translate(30px, 35px) rotateZ(5deg);
    z-index: 2;
  }
  
  [data-pos="bottom"] {
    transform: translate(57px, 39px) rotateZ(358deg);
    z-index: 1;
  }
  
  
  [data-rpos="top"] {
    color:  #333;
  }
  
  [data-rpos="mid"] {
    color: #333;
  }
  
  [data-rpos="bottom"] {
    color: #333; 
  }
  
  [data-horizontal="left"] {
    transform: rotateZ(-10deg);
    transition: transform 0.3s ease-in;
  }
  
  [data-horizontal="right"] {
    transform: rotateZ(10deg);
    transition: transform 0.3s ease-in;
  }
  
  [data-horizontal="hide"] {
    opacity: 0;
    transition: left 0.3s ease-out, opacity 0.3s ease-out, display 0s ease 0.3s;
  }
  
  @media (min-width: 992px) {  
    [data-name="slide"] {
      width: 483px;
      height: 483px;
    }
  
    [data-filler="fmid"] {
      width: 483px;
      height: 483px;
     }
    
     [data-filler="fbottom"]{
      width: 483px;
      height: 483px;
     }
  } 
  
}

.block {
  margin: 32px auto;
  height: 100%;  
  min-height: 40vh;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  transform: translate(-20px, 64px);
}

.wrapper {
  z-index: 101;
  width: 283px;
  height: 283px;
  position: relative;
  margin: 32px auto;
  padding-right: 32px;
}

.images {
  -webkit-user-drag: none;
  position: relative;
  height: 284px;
  width:284px;
  pointer-events: none;
  @media (min-width: 992px) {
    height: 460px;
    width:460px;
  }
}

.radio-container {
  display: flex;
  justify-content: center;
  padding-left: 24px;
  margin-top: 32px;
  transform: translateY(40px);
  @media (min-width: 992px) {
      transform: translateX(52px) translateY(0px);
  }  
}

.radio {
  display: inline-block;
  opacity: 0.5;
}

.radio .outer {
  height: 8px;
  width: 8px;
  display: block;
  float: left;
  margin: 10px 9px 10px 10px;
  border: 3px solid var(--grey-deep);
  border-radius: 50%;
  background-color: var(--grey-deep);
}


@media (min-width: 992px) {
  .block {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    display: flex;
    position: relative;
    transform: translateX(44px);
    
  }
  .wrapper {
    width: 483px;
    height: 483px;
    margin: 32px auto;
    position: relative;
  }
}  
