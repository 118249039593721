@import '../styles/variables';

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px 0;
  @media (min-width: $view-lg) {
    padding: 48px;
  }
}
