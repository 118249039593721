@import '../styles/variables';

.root {
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  width: fit-content;
  transition: all 0.3s cubic-bezier(0.48, 0.23, 1, 1);
  overflow: hidden;
  &:hover {
    &.primary {
      .pink {
        left: -16px;
      }
    }
  }
}

.text {
  z-index: 2;
  padding-right: 8px;
}

.pink {
  transition: all 0.3s ease-in;
  position: absolute;
  background-color: var(--funnel-accent);
  height: 130px;
  top: -20px;
  width: calc(100% + 32px);
  left: calc(100% - 50px);
  z-index: 1;
  transform: rotateZ(15deg);
}

.icon {
  font-family: 'athena-icons';
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
  position: absolute;
  right: 14px;
  z-index: 2;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  transform: translateZ(0);
  display: inline-block;
  vertical-align: middle;
}

.large {
  height: 40px;
  padding: 0 52px 0 13px;
  font-size: 14px;
  letter-spacing: -0.025em;
}

.small {
  height: 32px;
  padding: 0 42px 0 13px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.025em;
  .icon {
    right: 10px;
  }
  .pink {
    left: calc(100% - 42px);
  }
}

.primary {
  border: none;
  background-color: var(--funnel);
  color: var(--white);
  &:hover {
    background: var(--bright-purple);
    color: var(--white);
  }
}

.secondary {
  border: none;
  background-color: var(--white);
  .pink {
    background-color: var(--funnel);
  }
  .icon {
    color: var(--white);
  }
  &:hover {
    .pink {
      background-color: var(--funnel-accent);
    }
  }
}
