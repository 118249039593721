@import '../styles/variables';

.root {
  z-index: 9000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: height 0.6s ease-in-out;
  overflow-y:scroll;
  overflow-x:hidden;
  background: black;
  max-width: 1280px; 
  @media (min-width: $view-lg) {
    max-height: 100vh;
  }
}

.fade {
    display: block;
    position: fixed;
    opacity: 0.8;
    background-color: black;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    z-index: 8000;
    margin: 0;  
    transition: transform 0.6s  ease-in-out;   
}

.wrapper {
  position: relative;
  background-color: black;
  width: 90vw;
  min-height: 80vh;
  max-width: 1280px;
  transition: transform 0.6s  ease-in-out;
  @media (max-width: $view-lg) {
    width: 100vw;
    height: 100vh;
  }
}

.ratio {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  display: block;
  max-width: 1280px;
  transition: transform 0.6s  ease-in-out;
}

.active {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.3s ease-in-out ;
    margin-top: 48px;
    @media (min-width: $view-lg) {
      margin-top: 0px;
    }
}

.inactive {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    margin-top: 48px; 
    @media (min-width: $view-lg) {
      margin-top: 0px;
    }      
}

.intro {
  position: relative;
  max-height: 500px;
  padding-top: 24px;
  padding-bottom: 200px;
  transition: all 0.6s ease-in-out ;
  padding-left: 16px;
  width: 80%;
  @media(min-width: $view-lg) {
    padding-left: 48px;
    max-width: 488px;
  }  
}

.intro-active {
    transition: all 0.6s ease-in-out ;
}

.btn {
    color: white;
    background: transparent;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    margin-top: 24px;
    margin-right: 16px;
    right: 0;
    cursor: pointer;
    transition: transform 0.6s  ease-in-out;
    @media (min-width: $view-lg) {
      margin-right: 48px;
    }
}


.arrow {
    position: absolute;
    left: calc(50% - 0.5px);
    top: calc(50% + 3px);
    transform: translate(-50%, -50%); 
    color: white;
    font-size: 22px;
    line-height: 14px;
    font-weight: normal;
    z-index: 9999; 
}

.arrow-active {    
    transform: rotate(180deg);
    transition: transform 0.6s  ease-in-out;  
}

.video {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 64px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9000;
  @media (min-width: $view-lg) {
    transform: translate(-50%, -50%);
    margin-top: 0px
  }
}

.icon {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    // border: 1px solid #d0c9d4;
    border-radius: 50%;
    transition: all 0.3s;
    position: absolute;
    margin-right: 16px;
    right: 0;
    top: 0;
    margin-top: 16px;
    z-index: 9999;
    display: block;
    margin-bottom: 24px;
    background-color: var(--white);
    cursor: pointer;
    z-index: 9999;
    @media (min-width: $view-lg) {
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
    }   
    &:after {
      content: 't';
      font-family: 'athena-icons';
      font-size: 22px;
      line-height: 14px;
      font-weight: normal;
      position: absolute;
      left: calc(50% + 0.5px);
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .back {
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border: 1px solid #d0c9d4;
    border-radius: 50%;
    transition: all 0.3s;
    position: absolute;
    margin-bottom: 24px;
    margin-top: 16px;
    margin-left: 16px;
    z-index: 9500;
    cursor: pointer;
    top: 0;
    left: 0;
    @media (min-width: $view-lg) {
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;      
    }   
    &:after {
      content: 'd';
      font-family: 'athena-icons';
      font-size: 22px;
      line-height: 14px;
      font-weight: normal;
      position: absolute;
      left: calc(50% + 0.5px);
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .play {
    position: absolute;
    margin-top: 22px;
    left: 27%;
    z-index: 9999;
    color: var(--white);
    @media (min-width: $view-lg) {
      top: 4px;
      left: 64px;
    }     
  }

  .btn-holder {
    position: relative;
    max-width: 200px;
  }

  .cross-holder {
    position: relative;
  }

  .overlay {
    background: rgba(0,0,0, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9443;
  }
