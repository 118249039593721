@import '../styles/variables';

.root {
  font-family: 'athena-icons';
  font-size: 24px;
}

.default {
  font-size: 48px;
}

.small {
  font-size: 20px;
}

.light {
  color: var(--deep-accent);
}

.dark {
  color: var(--bright-accent);
}
