@import '../styles/variables';

.root {
  padding: 20px;
  padding-top: 20px;
  width: 100%;
  @media (min-width: $view-lg) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.icon {
  font-family: 'athena-icons';
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
}

.light {
  color: var(--deep-purple);
}

.dark {
  color: var(--white);
}

.light-background {
  background-color: var(--white);
}

.dark-background {
  background-color: var(--deep-purple);
}

.solid {
  height: 40px;
  padding: 0 32px;
  font-size: 16px;
  letter-spacing: -0.025em;
  border: none;
  border-radius: 4px;
  background: var(--deep-purple);
  color: var(--white);
  &.dark {
    background-color: var(--white);
    color: var(--deep-purple);
  }
  &:hover {
    background: var(--bright-purple);
    color: var(--white);
    &.dark {
      background: var(--purple-light);
      color: var(--deep-purple);
    }
  }
}

.full-elevation {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), -5px 4px 16px rgba(0, 0, 0, 0.08);
}

//mobile

.mobile-huge-top {
  padding-top: 96px;
}

.mobile-extra-large-top {
  padding-top: 64px;
}

.mobile-large-top {
  padding-top: 32px;
}

.mobile-medium-top {
  padding-top: 32px;
}

.mobile-small-top {
  padding-top: 16px;
}

.mobile-zero-top {
  padding-bottom: 0;
}

.mobile-huge-bottom {
  padding-bottom: 64px;
}

.mobile-extra-large-bottom {
  padding-bottom: 64px;
}

.mobile-large-bottom {
  padding-bottom: 32px;
}

.mobile-medium-bottom {
  padding-bottom: 32px;
}

.mobile-small-bottom {
  padding-bottom: 16px;
}

.mobile-zero-bottom {
  padding-bottom: 0;
}

//desktop

.desktop-huge-top {
  @media (min-width: $view-lg) {
    padding-top: 200px;
  }
}

.desktop-extra-large-top {
  @media (min-width: $view-lg) {
    padding-top: 128px;
  }
}

.desktop-large-top {
  @media (min-width: $view-lg) {
    padding-top: 96px;
  }
}

.desktop-medium-top {
  @media (min-width: $view-lg) {
    padding-top: 64px;
  }
}

.desktop-small-top {
  @media (min-width: $view-lg) {
    padding-top: 20px;
  }
}

.desktop-zero-top {
  @media (min-width: $view-lg) {
    padding-top: 0px;
  }
}

.desktop-huge-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 200px;
  }
}

.desktop-extra-large-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 128px;
  }
}

.desktop-large-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 96px;
  }
}

.desktop-medium-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 64px;
  }
}

.desktop-small-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 20px;
  }
}

.desktop-zero-bottom {
  @media (min-width: $view-lg) {
    padding-bottom: 0;
  }
}

.disclamer-and-btn {
  width: 95%;
  position: relative;
}

.disclaimer-side {
  @media (min-width: $view-lg) {
    position: absolute;
    left: 150px;
    top: -14px;
  }
}

